<template>
  <div class="addCard">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{
        cardId === "0" || cardId === 0 ? "添加卡片" : "编辑卡片"
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="所属分类">
        <el-select v-model="formList.cateId" placeholder="请选择卡片分类">
          <el-option
            v-for="item in options"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="万旅卡名称">
        <el-input
          v-model="formList.cardName"
          placeholder="请输入卡片名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="展示图">
        <send-image
          @addImageStr="addImageStr"
          :images="formList.cardImg"
          v-if="
            Number(cardId) == 0 || (Number(cardId) > 0 && formList.cardName)
          "
        ></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：375*375px</span
        >
      </el-form-item>
      <el-form-item label="标签">
        <el-input
          v-model="formList.label"
          placeholder="请输入卡片标签"
        ></el-input>
      </el-form-item>
      <el-form-item label="游戏ID">
        <el-input
          v-model="formList.gamerId"
          placeholder="购票成功后是否参与游戏活动  0 为不参与"
        ></el-input>
      </el-form-item>
      <el-form-item label="售价">
        <el-input
          v-model="formList.price"
          @input="onChange"
          placeholder="请输入卡片售价"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否退订" style="">
        <el-radio v-model="formList.unsubscribe" :label="1">是</el-radio>
        <el-radio v-model="formList.unsubscribe" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-radio v-model="formList.isShow" :label="1">是</el-radio>
        <el-radio v-model="formList.isShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="万旅网显示">
        <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
        <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="详情">
        <!-- <quill-editor
          class="richEdit"
          :options="quillOption"
          v-model.trim="formList.detail"
        >
        </quill-editor> -->
        <VueUeditorWrap
          v-model="formList.detail"
          :config="myConfig"
          ref="myTextEditor"
        ></VueUeditorWrap>
      </el-form-item>
      <el-form-item label="">
        <span style="color: red"> 建议尺寸375*375px</span>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="onSave()">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { showAll, addOrEditCard, cardDetail } from "../../api/wanlvCard";
import { sendFile } from "../../api/sendFile";
import quillConfig from "../../../quill-config";
import SendImage from "../../components/sendImage.vue";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "addCard",
  components: {
    SendImage,
    VueUeditorWrap,
  },
  props: {
    cardId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "150%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config", //打包
        //          UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      formList: {
        cateId: null,
        cardName: null,
        cardImg: null,
        label: null,
        price: null,
        detail: null,
        gamerId: null,
        isShow: 1,
        unsubscribe: 1,
        wlShow: 1,
        isNew:0
      },
      options: [],
    };
  },
  created() {
    this.getAllCategory();
    if (Number(this.cardId) !== 0) {
      this.getCardDetail();
    }
  },
  methods: {
    async onSave() {
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把信息填写完整",
            type: "warning",
          });
        }
      }
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(this.formList.price)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
      const { data } = await addOrEditCard({
        ...this.formList,
        cardId: Number(this.cardId),
      });
      if (data.code === 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    //获取卡片详情
    async getCardDetail() {
      const { data } = await cardDetail({
        cardId: Number(this.cardId),
      });
      if (data.code == 0) {
        this.formList.cateId = data.data[0].cateId;
        this.formList.cardName = data.data[0].cardName;
        this.formList.cardImg = data.data[0].cardImg;
        this.formList.label = data.data[0].label;
        this.formList.price = data.data[0].price;
        this.formList.detail = data.data[0].detail;
        this.formList.isShow = data.data[0].isShow;
        this.formList.unsubscribe = data.data[0].unsubscribe;
        this.formList.gamerId = data.data[0].gamerId;
        this.formList.wlShow = data.data[0].wlShow || 1;
        this.formList.isNew = data.data[0].isNew || 0;
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    onChange(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
    async getAllCategory() {
      const { data } = await showAll();
      if (data.code == 0) {
        this.options = data.data;
      }
    },
    addImageStr(image) {
      this.formList.cardImg = image;
    },
  },
};
</script>
<style lang="less" scoped>
.addCard {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    width: 800px;
    margin-top: 50px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }

    .el-form-item__content {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      .richEdit {
        height: 500px;
        .ql-container {
          height: 350px;
        }
      }
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }
  }
}
</style>